import React from 'react'
import { CardsTotales } from './CardsTotales'

export const CardsTotalesEntries = () => {

    return (
        <div className="row">
            <CardsTotales />
        </div>
    )
}
