import { fetchSinToken, fetchPrecioMetal } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2'


export const getListadoUbicacionesAdmin = (data) => {
    return async(dispatch) => {
        dispatch(startLoading())
        const resp = await fetchSinToken('get-ubicaciones');
        const body = await resp.json();
        if (body.ok) {
            console.log(body)
            dispatch(getListadoUbicaciones(body))
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Ha ocurrido un problema con el servidor',
                confirmButtonColor: "#1d8cf8",
                icon: 'error',
            })
            dispatch(finishLoading())
        }
    }
}
const getListadoUbicaciones = (data) => ({
    type: types.ayuntaGetInfo,
    payload: {
        data: data.data,
    }
});

export const startLoading = () => ({
    type: types.ayuntaStartLoading
})
export const finishLoading = () => ({
    type: types.ayuntaFinishLoading
})