import React from 'react'

export const CardsTotales = () => {
    return (
        <>
            <div className="col-md-4 animate__animated 
    animate__bounceInLeft 
    "

            >
                <div className="card-counter info">
                    <i className="fa fa-window-restore"></i>
                    <span className="count-numbers">+25</span>
                    <span className="count-name">Usuarios registrados</span>
                </div>
            </div>
            <div className="col-md-4 animate__animated 
                            animate__bounceInLeft 
                            pointer"
               
            >                <div className="card-counter danger">
                    <i className="fa fa-exclamation-triangle"></i>
                    <span className="count-numbers">200</span>
                    <span className="count-name">Viajes diarios</span>
                </div>
            </div>

            <div className="col-md-4 animate__animated 
                            animate__bounceInLeft 
                            pointer"
                
            >                <div className="card-counter success">
                    <i className="fa fa-tasks"></i>
                    <span className="count-numbers">45</span>
                    <span className="count-name">Nuevas descargas</span>
                </div>
            </div>
        </>
    )
}
