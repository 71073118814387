import React, { useEffect } from 'react'
import GoogleMapReact, { } from 'google-map-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getListadoUbicacionesAdmin } from '../../../actions/ayuntamiento';
import { useSelector } from 'react-redux';


const AnyReactComponent = ({ text }) => <div className='text-danger'>{text}</div>;

export const AdminMapasBaches = () => {
  const dispatch = useDispatch();
  const {data} = useSelector(state => state.ayunta)
  useEffect(() => {
    dispatch(getListadoUbicacionesAdmin());
  }, [dispatch])
  

  const [position, setPosition] = useState({
    lat: 20.966996, 
    lng: -89.623732
});

  return (
    <div style={{ height: '100vh', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyA6mxFVxqN6X66Vy93Fu3vlbcgpCTpxcmU" }}
      defaultCenter={position}
      defaultZoom={12}
    >
      {
        data.map((item, index) => (
          <AnyReactComponent
                      key={index + 1}
                      lat={item.longitud}
                      lng={item.latitud}
                      text={item.name}
                    />
        ))
      }

    </GoogleMapReact>
  </div>  )
}
