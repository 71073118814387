import React from 'react'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
export const AdminMapbox = () => {
  const render = (status) => {
    return <h1>{status}</h1>;
  };
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();
  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  return (
    <Wrapper apiKey={"AIzaSyA6mxFVxqN6X66Vy93Fu3vlbcgpCTpxcmU"} render={render}>
      
</Wrapper>
  )
}
