import React from 'react'
import { CardsTotales } from '../widgets/Dashboard/CardsTotales'
import { CardsTotalesEntries } from '../widgets/Dashboard/CardsTotalesEntries'

export const AdminDashboard = () => {
  return (
    <>
        <CardsTotalesEntries />
        <hr />
    </>
  )
}
